//
// General styles
// --------------------------------------------------
// Bootstrap overides
// -------------------------
// Grids
.container-fluid {
    max-width: 1178px;
}

.center-block {
    @extend .center-block;
    float: none;
}

// Button
.btn {
    font-family: $font-family-sans-serif-bold;
    font-size: $font-size-small;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.btn-default {
    &:focus,
    &:active,
    &:hover,
    &:focus {
        background: $btn-default-color;
        color: $btn-default-bg;
    }
}

.form-control {
    font-family: $font-family-sans-serif-bold;
    font-size: $font-size-small;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-left: 12px;
    padding-right: 12px;
    &:focus,
    &:active,
    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

// Global
// -------------------------
html {
    -webkit-font-smoothing: antialiased;
}

#page {
    overflow: hidden;
}

#main {
    position: relative;
}

.fullscreen {
    height: 100vh;
}

.invest-top {
    padding-top: 260px;
    padding-bottom: 50px;

    .arrow-spacer {
        height: 235px;
    }

}

.halfscreen {
    height: 50vh;
}

.threequarterscreen {
    height: 75vh;
}

img {
    max-width: 100%;
}

// Typography
// -------------------------
h1 {
    margin-top: 0;
    margin-bottom: 15px;
}

h2 {
    margin-top: 0;
    margin-bottom: 15px;
}

h3 {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: normal;
    font-family: $font-family-sans-serif-bold;
    letter-spacing: 0.5px;
    color: $gray;
}

p,
img,
form,
ul,
ol {
    margin-bottom: 15px;

    &::selection {
        background: $gray-light;
    }
}

strong {
    font-family: $font-family-sans-serif-bold;
    font-weight: normal;
    letter-spacing: 0.5px;
}

.lead {
    font-family: $font-family-sans-serif-bold;
    font-size: 14px;
    font-weight: normal;
    color: $gray;
    line-height: 1.2em;
    margin-bottom: 14px;
    text-align: center;
    letter-spacing: 0.5px;
}

p{
    a {
        color: $gray-dark;
        position: relative;
            &:after {
            position: absolute;
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background: $black;
            left: 50%;
            bottom: 0;
            @include transition(all 0.3s linear);
        }
        &:hover {
            &:after{
                width: 100%;
                left: 0;
            }
        }
    }

    a.btn{
        &:after {
            display: none;
        }
        &:hover,
        &:focus {
            text-decoration: none;

        }
    }
}

a {
    &:hover,
    &:focus {
        text-decoration: none;

    }
}

// Components
// -------------------------
.v-align-table {
    display: table;
    width: 100%;
    height: 100%;
}

.v-align-cell {
    display: table-cell;
    vertical-align: middle;
}

.animation-element {
    position: relative;
}

.arrow-spacer {
    margin-top: 20px;
    height: 161px;
    position: relative;
}

.arrow-animated {
    position: absolute;
    left: 50%;
    margin-left: -75px;
    top: 0;
    z-index: 999;
    height: 161px;
    width: 150px;
    text-align: center;
    .line {
        background-color: $white;
        display: block;
        width: 1px;
        height: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 46px;
        @include transition( height 0.6s linear );
    }
    strong {
        font-size: 12px;
        opacity: 0;
        @include transition( opacity 0.6s linear );
        @include transition-delay(1s);
    }
    svg {
        margin-left: -16px;
        position: absolute;
        left: 50%;
        bottom: -20px;
        opacity: 0;
        @include transition( opacity 0.3s linear, bottom 0.3s linear );
        @include transition-delay(0.3s);
    }
    &.animate {
        .line {
            height: 100px;
        }
        svg {
            bottom: 39px;
            opacity: 1;
        }
        strong {
            opacity: 1;
        }
    }
    &.black {
        .line {
            background-color: $black;
        }
    }
}

// Sections
// -------------------------
.section {
    padding-top: 40px;
    padding-bottom: 30px;
    position: relative;
}

.section-divider,
.footer-divider {
    position: relative;
    top: -40px; // To counter the section padding
    margin-top: 0;
    margin-bottom: 0;
}

.section-header {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 35px;
    text-align: center;
    &:after {
        display: block;
        content: '';
        width: 150px;
        height: 2px;
        background-color: $black;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -75px;
    }
}

.section-content {
    text-align: center;
}

// Properties
// -------------------------
#properties {
    padding-top: 0;
    .flexslider {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 55px;
        width: 70%;
        a {
            text-decoration: none;
            &:hover {
                border-width: 0;
            }
        }
    }
    .btn-primary {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.property-tabs {
    text-align: center;
    border-bottom: none;
    margin-bottom: 30px;
    padding-left: 0;
    padding-top: 20px;
    > li {
        float: none;
        display: inline-block;
        opacity: 0.4;
        padding-left: 10px;
        padding-right: 10px;
        > a {
            margin-right: 0;
            padding: 0;
            border: none;
            &:hover {
                background-color: transparent;
            }
        }
        &:hover {
            opacity: 0.75;
        }
        &.active {
            opacity: 1;
            > a,
            a:hover,
            a:focus {
                background-color: transparent;
                color: $black;
                border: none;
            }
        }
    }
    strong {
        display: block;
        font-size: 11px;
        text-transform: uppercase;
        margin-bottom: 6px;
    }
}

.tab-number {
    border: 2px solid $black;
    display: inline-block;
    height: 42px;
    width: 42px;
    border-radius: 21px;
    font-family: $font-family-serif;
    font-size: 26px;
    line-height: 40px;
    color: $black;
}

.centered-cta {
    text-align: center;
}

// Local Area
// -------------------------
#local-area {
    padding-bottom: 0;
}

#filters-container {
    overflow: hidden;
}

.toggle-filters {
    padding-left: 0;
    margin-bottom: 25px;
    li {
        list-style-type: none;
        margin-bottom: 4px;
        a {
            padding-left: 18px;
            position: relative;
            font-family: $font-family-sans-serif-bold;
            letter-spacing: 0.5px;
            &:before {
                content: '';
                display: block;
                height: 10px;
                width: 10px;
                border: 1px solid $black;
                background-color: transparent;
                border-radius: 10px;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -5px;
            }
        }
        &.active {
            a {
                &:before {
                    background-color: $black;
                }
            }
        }
    }
}

.open-filter-overlay {
    text-align: left;
    display: block;
    z-index: 1;
    position: relative;
    left: 50%;
    margin-left: -21px;
    margin-bottom: -21px;
    background: $white;
    height: 21px;
    width: 42px;
    border-radius: 0 0 21px 21px;
    text-align: center;
    font-family: $font-family-sans-serif-bold;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    overflow: hidden;
    span {
        position: relative;
        display: block;
        top: 0;
        height: 21px;
        @include transition(top 0.2s linear);
    }
}

.filters-open {
    .open-filter-overlay {
        span {
            top: -21px;
        }
    }
}

.clear-all {
    display: block;
    margin-bottom: 25px;
}

#local_area_map {
    height: 400px;
    width: 100%;
}

// Custom google maps controls
.control-wrapper {
    height: 24px;
    width: 52px;
    .zoom-in, .zoom-out {
        float: left;
        position: relative;
        height: 24px;
        width: 24px;
        background-color: $black;
        border-radius: 24px;
        cursor: pointer;
        &:after {
            font-family: $font-family-sans-serif-bold;
            font-size: 16px;
            display: block;
            position: absolute;
            line-height: 22px;
            height: 24px;
            width: 24px;
            text-align: center;
            color: $white;
        }

    }
    .zoom-in {
        &:after {
            content: '+';
        }
    }
    .zoom-out {
        margin-right: 4px;
        &:after {
            content: '-';
        }
    }
}

.infoBox {
    font-family: $font-family-sans-serif;
    font-size: 12px;
    color: $white;
    background-color: $black;
    padding: 15px 15px 52px 15px;
    width: 180px;
    text-align: left;
    .info-box-cat-name {
        color: #919191;
        border-bottom: 1px solid #919191;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: block;
    }
    .info-box-address {
        margin-bottom: 10px;
    }
    a {
        display: block;
        color: $white;
    }
    img {
        position: absolute !important;
        bottom: 15px;
        right: 15px;
    }
    &:after {
        position: absolute;
        bottom: -10px;
        margin-left: -5px;
        left: 50%;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #000;
    }
}

// Register interest
// -------------------------
#register-interest,
#future-developments {
    .section-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
    }
    form {
        margin-bottom: 0;
    }
    .btn {
        width: 100%;
    }
}

.form-control.mce_inline_error {
    border-color: red;
}

div.mce_inline_error {
    font-size: $font-size-small;
    color: red;
    padding-top: 4px;
    text-align: left;
}

#mce-responses {
    font-size: 12px;
    color: black;
    padding-top: 14px;
    text-align: left;
}

// Developments
// -------------------------
.development {
    font-size: $font-size-small;
}

// Strength and Experience
// -------------------------
#strength-experience {
    .feature {
        margin-top: 20px;
        margin-bottom: 0;
    }
}

// Floor plans
// -------------------------
.floor-plans {
    margin-top: 120px;
}

.plan {
    margin-bottom: 60px;
    font-size: $font-size-small;
    img {
        margin-bottom: 20px;
        display: block;
    }
}

.next-prev {
    padding-left: 0;
    overflow: hidden;
    margin-bottom: 40px;
    li {
        text-transform: uppercase;
        list-style-type: none;
        font-size: 12px;
    }

    img {
         margin-bottom: 5px;
    }
    .prev {
        float: left;
        img {
            margin-right: 5px;
        }
    }
    .next {
        float: right;
        img {
            margin-left: 5px;
        }
    }
}

// Priacy Policy
// -------------------------
.privacy-policy {
    padding-top: 70px;

    h1 {
        margin-bottom: 15px;
        font-size: 32px;
        font-family: caslongraphiqueefregular,"Times New Roman",Times,serif;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
    }

    h2 {
        font-family: avertabold,Helvetica,Arial,sans-serif;
        font-weight: 400;
        color: #77787A;
        letter-spacing: .5px;
        margin-bottom: 5px;
        font-size: 12px;
        text-transform: uppercase;
    }
}