//
// Header
// -------------------------
.navbar {
    min-height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-default {
    background-color: rgb(0, 0, 0);
    /* The Fallback */
    background-color: rgba(0, 0, 0, 0.45);
}

.navbar-brand {
    height: auto;
    width: 136px;
    padding: 0;
    img {
        height: 100%;
        width: 100%;
        margin-bottom: 0;
    }
}

.navbar-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.navbar-nav {
    > li {
        > a {
            text-transform: uppercase;
            font-family: $font-family-sans-serif-bold;
            font-size: $font-size-small;
            margin-left: 20px;
            margin-right: 20px;
            padding-left: 0;
            padding-right: 0;
            letter-spacing: 0.5px;
        }
    }
}


.nav-page-scroll,
.nav-pages,
.navbar-right {
    a {
        color: $gray-dark;
        position: relative;
            &:after {
            position: absolute;
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background: $black;
            left: 50%;
            bottom: 12px;
            @include transition(all 0.3s linear);
        }
        &:hover {
            &:after{
                width: 100%;
                left: 0;
            }
        }
    }
}

.navbar-right {
    a {
            &:after {
            background: $white;
            bottom: 40px;
        }
    }
}

// Nav overlay
.nav-wrapper {
    display: none;
    height: 110%;
    width: 100%;
    background-color: $gray-dark;
    text-align: center;
    position: fixed;
    z-index: $zindex-navbar-fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    backface-visibility: hidden;
    .v-align-table {
        position: static;
        height: 80%;
    }
}

.nav-mobile {
    padding: 0;
    margin: 0;
    li {
        font-family: $font-family-sans-serif-bold;
        font-size: 24px;
        padding: 0;
        margin: 0 0 20px 0;
        list-style-type: none;
        position: relative;
    }
    a {
        text-transform: uppercase;
        color: $white;
    }
}

.toggle-nav {
    float: right;
    height: 22px;
    width: 32px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 6px;
    background: none;
    border: none;
    z-index: 9999;
    cursor: pointer;
    padding: 0;
    outline: 0;
    @include rotate(0deg);
    @include transition(all .5s ease-in-out);
    span {
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: $white;
        opacity: 1;
        left: 0;
        @include rotate(0deg);
        @include transition(all .25s ease-in-out);
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 11px;
        }
        &:nth-child(4) {
            top: 22px;
        }
    }
}

.nav-open {
    overflow: hidden;
    .toggle-nav {
        span {
            &:nth-child(1) {
                top: 11px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                @include rotate(45deg);
            }
            &:nth-child(3) {
                @include rotate(-45deg);
            }
            &:nth-child(4) {
                top: 11px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

//
// FlexSlider overides
// --------------------------
#landing {
    .tag-line {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        text-align: center;
        color: $text-color;
        text-shadow: 1px 1px 1px rgba(255,255,255,.8);
        h1 {
            text-transform: uppercase;
        }
        .lead {
            color: $text-color;
        }
    }
    .flex-direction-nav {
        display: none;
    }
    .slides {
        height: 100%;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            z-index: 100;
        }
        li {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            height: 100%;
        }
    }
}

