//
// Responsive: Small screen / tablet
// --------------------------------------------------
// Typography
// -------------------------
h1 {
    font-size: $font-size-h1-desktop;
}

h2 {
    font-size: $font-size-h2-desktop;
}

h3 {
    font-size: $font-size-h3-desktop;
}

.lead {
    font-size: 18px;
    margin-bottom: 18px;
}

p,
img,
form,
ul,
ol,
.form-group {
    margin-bottom: 20px;
}

// Flexslider
// -------------------------
.flex-direction-nav a {
    width: 59px;
    height: 59px;
    margin-top: -30px;
}

.flex-direction-nav .flex-prev {
    background-image: url('../img/icon-slider-prev-wh.svg');
}

.flex-direction-nav .flex-next {
    background-image: url('../img/icon-slider-next-wh.svg');
}

// Components
// -------------------------
.arrow-spacer {
    margin-top: 40px;
}

//
// Header
// -------------------------
#primary-nav {
    position: absolute;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-brand {
    margin-left: 0 !important;
    margin-top: 26px;
    margin-bottom: 26px;
    width: 208px;
}

.toggle-nav {
    display: none;
}

.slides {
    strong {
        font-size: 20px;
    }
}

// Footer
// -------------------------
.site-footer {
    padding-top: 30px;
    padding-bottom: 35px;
    .footer-divider {
        top: -30px;
    }
}

.footer-logo,
.copyright,
.footer-links {
    margin-bottom: 0;
    display: inline-block;
}

.footer-logo,
.copyright {
    margin-right: 20px;
}

.credit {
    float: right;
    line-height: 34px;
    margin-bottom: 0;
}

// Page scrolling nav
// -------------------------
.secondary-nav {
    display: block;
    background: $white;
    z-index: $zindex-navbar;
    position: absolute;
    top: -60px;
    width: 100%;
    border-bottom: 1px solid $gray-light;
    .navbar-brand {
        height: 40px;
        width: 138px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        opacity: 0;
        top: 10px;
        @include transition(all 0.3s linear);
        svg {
            height: 100%;
            width: 100%;
        }
        path {
            fill: $black;
        }
        .text {
            display: none;
        }
    }
    .navbar-brand, .nav-pages {
        z-index: $zindex-navbar + 1;
    }
    &.affix {
        top: 0;
        position: fixed;
        .navbar-brand {
            opacity: 1;
            top: 0;
        }
        .nav-pages {
            opacity: 1;
            top: 0;
        }
    }
}

.nav-page-scroll,
.nav-pages {
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
    li {
        list-style-type: none;
        display: inline-block;
    }
    a {
        text-transform: uppercase;
        display: block;
        padding: 24px 0 20px;
        font-family: $font-family-sans-serif-bold;
        font-size: $font-size-small;
        letter-spacing: 0.5px;
        margin-left: 6px;
        margin-right: 6px;
    }
}

.nav-page-scroll {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-pages {
    float: right;
    position: relative;
    top: 10px;
    opacity: 0;
    @include transition(all 0.3s linear);
    @include transition-delay(0.2s);
}

// Sections
// -------------------------
.section {
    padding-top: 60px;
    padding-bottom: 50px;
}

.section-header {
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.section-divider {
    display: none;
}

// Sympathetic Development
// -------------------------
#sympathetic-development {
    .section-content {
        text-align: left;
    }
}

// Properties
// -------------------------
#properties {
    .flexslider {
        margin-bottom: 55px;
    }
    .section-content {
        text-align: left;
    }
}

// Local Area
// -------------------------
#filters-container {
    text-align: left;
}

#local_area_map {
    height: 520px;
}

.clear-all {
    text-align: center;
}

.open-filter-overlay {
    display: none;
}

// Register interest
// -------------------------
#register-interest,
#future-developments {
    .btn {
        width: auto;
    }
    .form-control {
        min-width: 200px;
    }
    .form-control,
    .btn,
    .form-group {
        vertical-align: top;
    }
    .form-group {
        margin-right: 12px;
    }
}

// Developments
// -------------------------
.development {
    p {
        margin-bottom: 0;
    }
}

// Strength and Experience
// -------------------------
#strength-experience {
    .section-content {
        text-align: left;
    }
    .feature {
        margin-top: 40px;
    }
}

// Experienced team
// -------------------------
#strength-experience,
#experienced-team {
    .section-content {
        text-align: left;
    }
}

// Floor plans
// -------------------------
.floor-plans {
    margin-top: 172px;
}
