//
// Footer
// -------------------------
.site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 25px;
    font-size: $font-size-small;
    .footer-divider {
        top: -20px;
    }
}

.copyright {
    display: block;
    text-transform: uppercase;
}

.footer-links {
    padding-left: 0;
    li {
        display: inline-block;
        list-style-type: none;
        margin-right: 10px;
    }
}

.credit {
    text-transform: uppercase;
    a {
        font-family: $font-family-sans-serif-bold;
        letter-spacing: 0.5px;
    }
}

.copyright,
.credit,
.footer-links {
    margin-bottom: 10px;
}
