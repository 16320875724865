// Include custom variables before bootstrap variables
@import "theme/variables";

// bower:scss
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_variables.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_mixins.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_normalize.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_print.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_glyphicons.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_scaffolding.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_type.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_code.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_grid.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_tables.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_forms.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_buttons.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_component-animations.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_dropdowns.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_button-groups.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_input-groups.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_navs.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_navbar.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_breadcrumbs.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_pagination.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_pager.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_labels.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_badges.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_jumbotron.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_thumbnails.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_alerts.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_progress-bars.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_media.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_list-group.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_panels.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_responsive-embed.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_wells.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_close.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_modals.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_tooltip.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_popovers.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_carousel.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_utilities.scss";
@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_responsive-utilities.scss";
// endbower

@import "theme/flexslider";
// @import "theme/mailchimp";

@import "theme/header";
@import "theme/footer";
@import "theme/pages";
@import "theme/sidebar";


@import "theme/theme";

// Extra small screen / phone
@media only screen and (min-width: $screen-xs-min) {
  @import "theme/screen-xs";
}

// Small screen / tablet
@media only screen and (min-width: $screen-sm-min) {
  @import "theme/screen-sm";
}

// Medium screen / desktop
@media only screen and (min-width: $screen-md-min) {
  @import "theme/screen-md";
}

// Large screen / wide desktop
@media only screen and (min-width: $screen-lg-min) {
  @import "theme/screen-lg";
}
